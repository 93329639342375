exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-ec-product-page-mdx": () => import("./../../../src/templates/ProjectLayout.js?__contentFilePath=/home/runner/work/jcduran-gatsby/jcduran-gatsby/_projects/project/ec-product-page.mdx" /* webpackChunkName: "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-ec-product-page-mdx" */),
  "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-sales-tasks-dashboard-mdx": () => import("./../../../src/templates/ProjectLayout.js?__contentFilePath=/home/runner/work/jcduran-gatsby/jcduran-gatsby/_projects/project/sales-tasks-dashboard.mdx" /* webpackChunkName: "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-sales-tasks-dashboard-mdx" */),
  "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-video-link-scraper-mdx": () => import("./../../../src/templates/ProjectLayout.js?__contentFilePath=/home/runner/work/jcduran-gatsby/jcduran-gatsby/_projects/project/video-link-scraper.mdx" /* webpackChunkName: "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-video-link-scraper-mdx" */),
  "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-web-refinery-mdx": () => import("./../../../src/templates/ProjectLayout.js?__contentFilePath=/home/runner/work/jcduran-gatsby/jcduran-gatsby/_projects/project/web-refinery.mdx" /* webpackChunkName: "component---src-templates-project-layout-js-content-file-path-home-runner-work-jcduran-gatsby-jcduran-gatsby-projects-project-web-refinery-mdx" */)
}

